<template>
  <b-card class='mt-4'>

    <div class='container mt-4'>
      <validation-observer ref="editUserFormValidation"></validation-observer>
        <b-form @submit.prevent>

            <b-row class="justify-content-center">
                <b-col md="5" sm="10">
              <b-form-row>
                <b-col>
                    <b-form-group label-for="oldPassword">
                    <template #label>
                        Old Password <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                        name="Old Password"
                    >
                        <b-form-input
                        id="oldPassword"
                        placeholder="Old Password"
                        v-model="oldPassword"
                        type="password"
                        />
                    </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                  <b-form-group label-for="newPassword">
                      <template #label>
                          New Password <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                          name="newPassword"
                          :rules="{
                          required: true,
                          regex:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[\S]{8,}$/,
                        }"
                      >
                          <b-form-input
                          id="newPassword"
                          placeholder="New Password"
                          v-model="newPassword"
                          type="password"

                          />
                      </validation-provider>
                      <span class="font-weight-bolder text-danger"
                      >The password must be at least 8 characters long, at least one
                      lowercase letter, at least one uppercase letter, at least one digit
                      and at least one special character (e.g., @$!%*?&)</span
          >
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <b-form-row>
                  <b-col>
                    <b-form-group label-for="confirmPassword">
                    <template #label>
                        Confirm Password <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                        name="Confirm Password"
                    >
                        <b-form-input
                        id="confirmPassword"
                        placeholder="Confirm Password"
                        v-model="confirmPassword"
                        type="password"
                        />
                    </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                
            </b-col>
            </b-row>

            <b-form-group class="mt-2 text-right" >
              <b-button type="submit"  variant="info" pill @click="validationForm">
                Update
              </b-button>
            </b-form-group>

        </b-form>
    </div>
  </b-card>

</template>



<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import util from "@/util.js";


export default {
    components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  
  mixins: [util],
 
  data() {
    return {
      required,
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    };
  },
  
  methods: {
    ...mapActions({
      updateUser: "appData/updateUser",
     }),
    async validationForm() {
        if (this.oldPassword === ""){
            this.displayStringError("Please Enter Old Password");
        }
        else if (this.newPassword !== this.confirmPassword){
            this.displayStringError("Passwords Do not match");
        }
        else if (this.oldPassword === this.newPassword){
            this.displayStringError("Same Old Password");
        }
        else{
            await this.submit();
        }
    },
    async submit() {
        const res = await this.updateUser({
            payload: {password: this.newPassword},
            pk: this.getLoggedInUser.id,
        });
        if (res.status === 200) {
        this.$swal({
            title: "Password updated successfully",
            icon: "success",
        });}
     
    },
},
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
  
};
</script>
